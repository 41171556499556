/**
 * This file was automatically generated with 'graphql-code-generator' and should not be edited.
 * To regenerate this file update some *.graphql file and run `yarn codegen`
 *
 * See settings in project file: 'codegen.ts'
 */

/* eslint-disable */

import * as Types from '../../../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type AdminCertificationDetailFragment = {
  __typename?: 'AdminCertification';
  id: any;
  name: string;
  validFrom?: any | null;
  validTo?: any | null;
  validityInMonths?: number | null;
  description: string;
};

export type AdminCertificationDetailQueryVariables = Types.Exact<{
  adminCertificationDetailId: Types.Scalars['AdminCertificationID']['input'];
}>;

export type AdminCertificationDetailQuery = {
  __typename?: 'Query';
  object:
    | {
        __typename?: 'AdminCertification';
        id: any;
        name: string;
        validFrom?: any | null;
        validTo?: any | null;
        validityInMonths?: number | null;
        description: string;
      }
    | { __typename?: 'ObjectDoesNotExistResponse'; objectId?: string | null };
};

export const AdminCertificationDetailFragmentDoc = gql`
  fragment AdminCertificationDetail on AdminCertification {
    id
    name
    validFrom
    validTo
    validityInMonths
    description
  }
`;
export const AdminCertificationDetailDocument = gql`
  query AdminCertificationDetail($adminCertificationDetailId: AdminCertificationID!) {
    object: adminCertificationDetail(id: $adminCertificationDetailId) {
      ... on AdminCertification {
        ...AdminCertificationDetail
      }
      ... on ObjectDoesNotExistResponse {
        objectId
      }
    }
  }
  ${AdminCertificationDetailFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class AdminCertificationDetailGQL extends Apollo.Query<
  AdminCertificationDetailQuery,
  AdminCertificationDetailQueryVariables
> {
  override document = AdminCertificationDetailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
